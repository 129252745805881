import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';

let instance = null;

class Signin extends React.Component {
  constructor() {
    super();

    this.state = {};
    instance = this;
  }

  onSignInGoogleUser(googleUser) {
    instance.props.onSignIn(googleUser);
  };

  render() {
    return (
      <div style={{'textAlign': 'center', 'marginTop': '100px'}}>

        <GoogleLogin
          clientId="1098928176852-d3q5a1n1mu4n8musgr4j8q95ciq39t7t.apps.googleusercontent.com"
          buttonText="Login With Google"
          onSuccess={instance.onSignInGoogleUser}
          onFailure={instance.onSignInGoogleUser}
          isSignedIn={instance.props.isSignedIn}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    );
  }
}

Signin.propTypes = {
  onSignIn: PropTypes.func,
};

export default Signin;
